import * as React from 'react'
import Layout from '../components/Layout'
import AlertBanner from "../components/AlertBanner";
import {Helmet} from "react-helmet"
import {StaticImage} from "gatsby-plugin-image"
import {Link} from 'gatsby'

//Modular stylesheet
import {headingWrapper, headingImageWrapper, headingImage, headingImageOverlayWrapper, headingImageText, headingImageSubtext, headingImageButton, introCard
, introCardContentWrapper, introCardContent, introCardContentHeading, middleCardWrapper, middleCardImage, middleCardHeading, middleCardText, endCardContentWrapper, endCard
, endCardContent, middleCardImages} from "../components/styles/titlepage.module.css"

//Global stylesheet
import "../components/styles/global.css"

const Index = () => {
    return(
        <Layout pageTitle={"Welcome to Bangbiangyang"}>
            <Helmet>
                <meta charSet="UTF-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>

                <title>Explore Bangbiangyang | Official site for the Bangbiangyang Ministry of Tourism</title>

                <meta name="author" content="aqq.xyz"/>
                <meta name="description" content="Welcome to Bangbiangyang. Find official information, advice, and inspiration for your journey through the Bangbiangyang isles here.
                Discover the fantastic places, opportunities, and events that Bangbiangyang has to offer by visiting the official site for Tourism Bangbiangyang."/>

                <meta property="og:title" content="Explore Bangbiangyang | Official site for the Bangbiangyang Ministry of Tourism"/>
                <meta property="og:description" content="Welcome to Bangbiangyang. Find official information, advice, and inspiration for your journey through the Bangbiangyang isles here.
                Discover the fantastic places, opportunities, and events that Bangbiangyang has to offer by visiting the official site for Tourism Bangbiangyang."/>
                <meta property="og:url" content="bangbiangyang.aqq.xyz/"/>
                <meta property="og:site_name" content="bangbiangyang.aqq.xyz/"/>

                <link rel="icon" href="../static/favicon.ico" />
            </Helmet>
            <AlertBanner> </AlertBanner>
            <div className={headingWrapper}>
                <div className={headingImageWrapper}>
                    <StaticImage className={headingImage} src="https://upload.wikimedia.org/wikipedia/commons/d/d8/Tuscany_landscape_view_skyline.jpg" alt="."/>
                    <div className={headingImageOverlayWrapper}>
                        <p className={headingImageText}>A world of discovery </p>
                        <p className={headingImageSubtext}>Start planning your Bangbiangyang journey today.</p>
                        <Link className={headingImageButton} to="/">Information for first-time visitors</Link>
                    </div>
                </div>
            </div>
            <div className="midContentWrapper">
                <div className={introCard}>
                    <div className={introCardContentWrapper}>
                        <p className={introCardContentHeading}>
                            Maybe you've always wanted to explore the streets of Spankingrad.
                        </p>
                        <p className={introCardContent}>
                            There's no shortage of opportunity in Bangbiangyang. Take a stroll along an ancient crater rim, gazing at the glistening Deprexxizawa skyline.
                            Find your spiritual self as you ascend the sacred spires of central Yang, or find new friends in the world's largest red-light district. Discover
                            millennia of culture in the Sergeant Sad Memorial Reserve, and experience the culture of the future at an evening biangYAY! concert.
                        </p>
                        <p className={introCardContent}>
                            Wherever your life takes you, find its destination here.
                        </p>
                    </div>
                </div>

                <div className={middleCardWrapper}>
                    <div className={middleCardText}>
                        <h3  data-sal="slide-up" className={middleCardHeading}>
                            Discover new places
                        </h3>
                        <p data-sal="slide-up">
                            Ayayayayayaay
                        </p>
                    </div>
                    <div className={middleCardImage} data-sal="slide-up" >
                        <StaticImage className={middleCardImages} src="https://upload.wikimedia.org/wikipedia/commons/b/b8/JR_Hokkaido_Kiha40_near_Jomon_tunnel_20200811_0918_30358.jpg"
                                     alt="." width={3648} height={5472}/>
                    </div>
                </div>

                <div className={middleCardWrapper}>
                    <div className={middleCardImage} data-sal="slide-up">
                        <StaticImage className={middleCardImages} src="https://upload.wikimedia.org/wikipedia/commons/b/b8/JR_Hokkaido_Kiha40_near_Jomon_tunnel_20200811_0918_30358.jpg"
                                     alt="." width={3648} height={5472}/>
                    </div>
                    <div className={middleCardText}>
                        <h3 className={middleCardHeading} data-sal="slide-up" >
                            Explore spiritual heights
                        </h3>
                        <p data-sal="slide-up" >
                            Ayayayayayaay
                        </p>
                    </div>
                </div>

                <div className={endCard}>
                    <div className={endCardContentWrapper}>
                        <h4 className={endCardContent}>
                            Incredible destinations await.
                        </h4>
                    </div>
                </div>
            </div>


        </Layout>
    )
}

export default Index