import * as React from 'react'
import { Link } from 'gatsby'

import {alertBannerGeneral, alertBannerText, alertBannerLink, alertBannerPre} from './styles/layout.module.css'

const AlertBanner = () => {
    return(
        <div className={alertBannerGeneral}>
            <p className={alertBannerText}>
                <span className={alertBannerPre}>Travel advisory:  </span>
                Updated travel restrictions are now being enforced.  <Link className={alertBannerLink} to="/traveladvisories">Learn more</Link>
            </p>
        </div>
    )
}

export default AlertBanner
